<template>
  <div class="relative w-full pb-full bg-gray-300">
    <img
      v-for="(image, index) in images"
      :key="`image-${index}`"
      v-lazy="image"
      class="object-cover absolute h-full w-full inset-0 transition-all duration-300 ease-in-out"
      :class="{
        'opacity-100': index === currentIndex,
        'opacity-0': index !== currentIndex
      }"
      :alt="title"
    />
    <div
      v-if="images.length > 1"
      class="absolute z-10 w-full h-full pb-4 flex flex-col items-center justify-end"
    >
      <div
        class="w-full h-full flex justify-between items-center px-4 transition-opacity duration-300 ease-in-out opacity-0 hover:opacity-100"
      >
        <div
          role="button"
          class="bg-white w-8 h-8 rounded-full flex items-center justify-center transition-opacity duration-300 ease-in-out opacity-70 hover:opacity-100 shadow-sm"
          @click.prevent="changeSlide(-1)"
        >
          <b-icon name="chevron-left" />
        </div>
        <div
          role="button"
          class="bg-white w-8 h-8 rounded-full flex items-center justify-center transition-opacity duration-300 ease-in-out opacity-70 hover:opacity-100 shadow-sm"
          @click.prevent="changeSlide(1)"
        >
          <b-icon name="chevron-right" />
        </div>
      </div>
      <div class="flex items-center">
        <span
          v-show="currentIndex > 3"
          class="bg-white rounded-full h-3 w-3 opacity-50 mr-2 shadow-sm"
        ></span>
        <span
          v-for="(slide, index) in images"
          v-show="
            currentIndex > 2
              ? index >= currentIndex - 2 && index <= currentIndex
              : index < 3
          "
          :key="`slide-${index}`"
          class="bg-white rounded-full w-4 h-4 shadow-sm transition duration-300 ease-in-out"
          :class="[
            {
              'opacity-50': index !== currentIndex,
              'mr-2': index !== images.length - 1
            }
          ]"
        ></span>
        <span
          v-show="currentIndex !== images.length - 1 && images.length > 3"
          class="bg-white rounded-full h-3 w-3 opacity-50 shadow-sm"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import BIcon from "./BIcon";

export default {
  name: "BSlideshow",

  components: {
    BIcon
  },

  props: {
    images: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      currentIndex: 0
    };
  },

  methods: {
    changeSlide(num) {
      const tempIndex = this.currentIndex + num;
      let newIndex = tempIndex;
      if (tempIndex > this.images.length - 1) {
        newIndex = 0;
      } else if (tempIndex < 0) {
        newIndex = this.images.length - 1;
      }
      this.currentIndex = newIndex;
    }
  }
};
</script>
