<template>
  <div class="mx-auto w-1/2 py-6">
    <router-link :to="{ name: 'preview-list-view' }">
      <b-button variant="secondary" class="mb-4">
        <b-icon name="arrow-left" class="mr-2" />
        {{ $t("return") }}
      </b-button>
    </router-link>
    <b-content-card :item="item" :config="detailConfig" type="detail" />
  </div>
</template>

<script>
import BButton from "@/components/BButton";
import BContentCard from "@/components/BContentCard";
import BIcon from "@/components/BIcon";
import { mapState } from "vuex";

export default {
  name: "DetailView",

  components: {
    BButton,
    BContentCard,
    BIcon
  },

  computed: {
    ...mapState("builder", ["items", "detailConfig"]),
    item() {
      return this.items[this.itemIndex];
    },
    itemIndex() {
      return this.items.findIndex(item => item.slug == this.$route.params.slug);
    }
  }
};
</script>
